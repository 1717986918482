<template>
  <v-row>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <vc-date-picker
        v-model="temporada.fromValidez"
        outlined
        dense
        :min-date="new Date()"
        mode="date"
        :model-config="modelConfig"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :label="$t('lbl.fromValidez')"
            :disabled="desabilitar"
            outlined
            dense
            :value="inputValue"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>
    </v-col>
    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <vc-date-picker
        v-model="temporada.toValidez"
        outlined
        dense
        :min-date="$moment(temporada.fromValidez).add(1, 'days').toDate()"
        mode="date"
        :model-config="modelConfig"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :disabled="!temporada.fromValidez || desabilitar"
            :label="$t('lbl.toValidez')"
            outlined
            dense
            :value="inputValue"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>
    </v-col>
    <v-col
      v-if="$store.state.app.temporadasFlightMarkups.length > 1 && !desabilitar"
      cols="12"
      sm="2"
      md="2"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteTemporadaFlightMarkus(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiEyePlus,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    temporada: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    desabilitar: {
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiEyePlus,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },

  methods: {
    ...mapMutations(['deleteTemporadaFlightMarkus']),
  },
}
</script>
