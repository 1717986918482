var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","min-date":new Date(),"mode":"date","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$t('lbl.fromValidez'),"disabled":_vm.desabilitar,"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.temporada.fromValidez),callback:function ($$v) {_vm.$set(_vm.temporada, "fromValidez", $$v)},expression:"temporada.fromValidez"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"5"}},[_c('vc-date-picker',{attrs:{"outlined":"","dense":"","min-date":_vm.$moment(_vm.temporada.fromValidez).add(1, 'days').toDate(),"mode":"date","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
return [_c('v-text-field',_vm._g({attrs:{"disabled":!_vm.temporada.fromValidez || _vm.desabilitar,"label":_vm.$t('lbl.toValidez'),"outlined":"","dense":"","value":inputValue}},inputEvents))]}}]),model:{value:(_vm.temporada.toValidez),callback:function ($$v) {_vm.$set(_vm.temporada, "toValidez", $$v)},expression:"temporada.toValidez"}})],1),(_vm.$store.state.app.temporadasFlightMarkups.length > 1 && !_vm.desabilitar)?_c('v-col',{attrs:{"cols":"12","sm":"2","md":"2"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteTemporadaFlightMarkus(_vm.pos)}}},[_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,2947043082)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }